import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Camera } from 'react-camera-pro';
import LoadingSpinner from './LoadingSpinner';

const CameraPage = () => {
  const camera = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const takePhoto = async () => {
    if (!camera.current) return;

    const photo = camera.current.takePhoto();
    setLoading(true);

    try {

      let image = dataURLToFile(photo, 'captured-image.jpg');
      const formData = new FormData();
      formData.append('image', image);

      const response = await fetch(process.env.REACT_APP_SERVER_URL + '/submit', {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json' },
        body: formData,
      });

      const responseData = await response.json();

      // Navigate to InfoPage with the photo and response data
      navigate('/info', { state: { photo, response: responseData } });
    } catch (error) {
      console.error('Error submitting photo:', error);
      setLoading(false);
    }
  };

  return (
    <div onClick={takePhoto}>
      {!loading && (
        <Camera
          ref={camera}
          facingMode="environment"
        />
      )}
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="absolute inset-x-0 bottom-4 text-center text-gray-300 cursor-pointer">
          Tap anywhere to take a photo
        </div>
      )}
    </div>
  );
};

function dataURLToFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while(n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([new Blob([u8arr], {type:mime})], filename, {type: mime});
}

export default CameraPage;
