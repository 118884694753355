import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <img src="/logo.png" alt="Water You Using Logo" className="w-2/3 md:w-1/2 max-w-xs" />
      <p className="mt-4 text-lg text-gray-600 w-1/3">Water-You-Using is a free progressive web app, which helps consumers determine the water impact of their actions. Tap the button below to open your camera and scan any nutrition label. The app will provide relevant information on the water impact of the product.</p>
      <button
        onClick={() => navigate('/camera')}
        className="mt-6 px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700"
      >
        Open Camera
      </button>
    </div>
  );
};

export default HomePage;
