import React from 'react';
import { useLocation } from 'react-router-dom';

const InfoPage = () => {
  const location = useLocation();
  const { photo, response } = location.state || {};

  // Extract relevant data from the response
  const { Score, ...info } = response || {};

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Top half: Image */}
      <div className="flex items-center justify-center h-1/2 bg-white shadow">
        {photo && (
          <img
            src={photo}
            alt="Captured"
            className="object-contain max-h-full max-w-full"
          />
        )}
      </div>

      {/* Bottom half: Information */}
      <div className="flex flex-col h-1/2 bg-gray-50 p-6 overflow-auto">
        {/* Overall Score */}
        <div className="flex items-center justify-around mb-6">
          <div className="flex flex-col items-center">
            <div
              className={`w-20 h-20 flex items-center justify-center rounded-full text-white text-xl font-bold ${
                Score < 50
                  ? 'bg-red-500'
                  : Score < 70
                  ? 'bg-yellow-500'
                  : 'bg-green-500'
              }`}
            >
              {Score}
            </div>
            <label className="mt-2 text-sm text-gray-700">Overall Score</label>
          </div>
          <div className="text-sm text-gray-700 max-w-md">
            <p className="mb-2">Scores are derived from 3 characteristics:</p>
            <ol className="list-decimal list-inside">
              <li>Water required for production (liters/kg of product)</li>
              <li>Amount of the ingredient used in the product</li>
              <li>Type of water used (potable, grey, or black)</li>
            </ol>
          </div>
        </div>

        {/* Analysis */}
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Analysis</h2>
        <div>
          {Object.entries(info).map(([key, value]) => (
            <div key={key} className="mb-4">
              <button
                className="w-full text-left text-gray-800 bg-gray-200 py-2 px-4 rounded shadow hover:bg-gray-300"
                onClick={(e) => {
                  const panel = e.currentTarget.nextElementSibling;
                  panel.classList.toggle('hidden');
                }}
              >
                {key}
              </button>
              <div className="hidden bg-gray-100 p-4 rounded shadow">
                <p className="text-sm text-gray-700">{value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
